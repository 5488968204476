
@import 'assets/scss/_config-base';

html {
  @include rem-baseline;
  cursor: default;
  height: 100%;
  font-weight: $font-weight-base;
  font-family: $font-main;
  word-break: break-word;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  max-height: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $color-body-bg;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.has--no-scroll {
    overflow: hidden;
  }
}

#root {
  height: 100%;
  min-height: 100%;
}

.rn-title {
  font-family: 'Montserrat', sans-serif;
}
