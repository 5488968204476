
/**
 * General Variables
 * Call on every SASS files
 */

// Base colors
$color-body-bg: $color-white-ice !default;

// Material
$standard-ease   : cubic-bezier(0.4, 0.0, 0.2, 1);
$accelerate-ease : cubic-bezier(0.4, 0.0, 1, 1);
$decelerate-ease : cubic-bezier(0.0, 0.0, 0.2, 1);

// Default Transition
$transition-duration: 250ms;
$transition-function: $accelerate-ease;

// Sidebar
$sidebar-width: 270px;

// Header
$header-height: 60px;
