
@import '../../assets/scss/settings/_config-base';

.rn-header {
  @include flex-centered;
  height: $header-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  background-color: transparent;
  transition-property: transform, background;
  transition-duration: $transition-duration;
  transition-timing-function: $accelerate-ease;
  z-index: z('header');

  @include mq('lg', min) {
    transform: translateY(-100%);
  }

  &__container {
    @include rem(padding, 10px 20px);
    @include flex-spaced;
  }

  &.fill {
    background-color: darken($color-black-russian, 2%);
  }

  .button {
    @include reset-button;
    outline: none !important;
    display: flex;
  }

  .logo {
    @include rem(max-width, 35px);
  }

  .menu,
  .close {
    cursor: pointer;
    fill: #fff;
  }

  .menu {
    @include rem(width, 20px);
    @include rem(height, 20px);
  }

  .close {
    @include rem(width, 14px);
    @include rem(height, 14px);
  }
}
