
/*
 * Montserrat
 */

 @font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/Montserrat-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Montserrat-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/Montserrat-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Montserrat-Bold.ttf') format('truetype');
  font-display: swap;
}

/*
 * Roboto
 */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/Roboto-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/Roboto-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Roboto-Bold.ttf') format('truetype');
  font-display: swap;
}
