/**
 * Colors
 */
$color-pigment-green   : #18b45c;
$color-goblin          : #38853c;
$color-spring-green    : #117e40;
$color-light-coral     : #ff8787;
$color-persian-red     : #d32f2f;
$color-orange-peel     : #ffa000;
$color-navy-blue       : #1976d2;

$color-whisper         : #eeeeee;
$color-white-ice       : #ebeef1;
$color-solitude        : #e2e3e5;
$color-light-grey      : #dddddd;
$color-grey            : #757575;
$color-dim-gray        : #666666;
$color-night-rider     : #313131;
$color-ebony           : #282c34;
$color-black-russian   : #181923;
$color-black-russian-2 : #1f202e;
$color-black-russian-3 : #1b1b27;
$color-black           : #000000;

$color-primary         : $color-black-russian;
$color-accent          : $color-spring-green;

%active-link {
  color: $color-accent;
  text-shadow: 0 0 1px $color-accent;
}
