
@import '../../assets/scss/settings/_config-base';

.rn-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  max-width: 80vw;
  color: #fff;
  background-color: $color-black-russian;
  transition: transform $transition-duration $accelerate-ease;
  z-index: z('sidebar');
  overflow-y: auto;

  @include mq('lg', max) {
    transform: translateX(-110%);
  }

  &.is--open {
    @include mq('lg', max) {
      transform: translateX(0);
    }
  }

  & > div {
    &:not(:last-child) {
      border-bottom: 1px solid lighten($color-black-russian, 8%);
    }
  }

  &__top {
    @include rem(padding, 50px);
    @include flex-centered;

    .logo {
      @include rem(max-width, 60px);
    }
  }

  &__middle {
    @include rem(padding, 20px 25px);

    .menu {
      &-items {
        @include reset-list;
      }

      &-item {
        @include rem(padding, 8px 0);
      }

      &-link {
        @include rem(font-size, 14px);
        @include font-weight(semi-bold);
        @include reset-link;
        text-transform: uppercase;
        transition: color $transition-duration $accelerate-ease;

        &:hover,
        &.is--active {
          @extend %active-link;
        }
      }
    }
  }

  &__bottom {
    @include rem(padding, 20px 25px);

    .contact {
      &-items {
        @include reset-list;
      }

      &-item {
        @include rem(padding, 8px 0);
        display: flex;
        align-items: center;

        svg {
          @include rem(max-width, 22px);
          @include rem(margin-right, 8px);
          width: 100%;
          fill: $color-pigment-green;
        }
      }

      &-link,
      &-label {
        @include rem(font-size, 14px);
        transition: color $transition-duration $accelerate-ease;
      }

      &-link {
        @include reset-link;

        &:hover {
          @extend %active-link;
        }
      }
    }

    .rn-social__items {
      @include rem(margin-top, 30px);
    }
  
    .language {
      @include rem(padding, 30px 0);
      @include rem(font-size, 15px);
      @include font-weight(semi-bold);

      svg {
        @include rem(width, 20px);
        @include rem(margin-right, 10px);
      }
      
      &__active {
        @include rem(border-radius, 4px 4px 0 0);
        @include rem(padding, 5px 10px);
        @include flex-spaced;
        cursor: pointer;
        background-color: lighten($color-black-russian, 8%);

        &:not(.is--open) {
          @include rem(border-radius, 4px);

          svg.up-arrow {
            transform: scaleY(-1);
          }  
        }

        span {
          display: inline-flex;
          align-items: center;
        }

        svg.up-arrow {
          @include rem(width, 15px);
          transition: transform $transition-duration $accelerate-ease;
          fill: #fff;
        }
      }

      &__dropdown {
        @include rem(border-radius, 0 0 4px 4px);
        background-color: lighten($color-black-russian, 8%);
        transition: transform $transition-duration $accelerate-ease;
        transform-origin: top;

        &:not(.is--open) {
          transform: scaleY(0);
        }
      }

      &__items {
        @include reset-list;
      }

      &__item {
        @include rem(padding, 5px 10px);
        cursor: pointer;
        display: flex;
        align-items: center;

        @include hover-supported() {
          background-color: lighten($color-black-russian, 12%);
        }
      }
    }
  }
}
