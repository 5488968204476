
@import '../../../assets/scss/_config-base';

.rn-contact {
  &__title {
    @include rem(margin-bottom, 10px);
    text-align: center;
  }

  &__subtitle {
    @include rem(font-size, 15px);
    @include rem(margin-bottom, 30px);
    text-align: center;
  }

  .rn-form {
    &__group {
      &.button {
        @include mq('md', max) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
